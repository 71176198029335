/*
 * 업무구분: 수익증권>고객>인터넷펀드거래서비스신청>인터넷펀드거래서비스신청
 * 화 면 명: MSPFS109M
 * 화면설명: 인터넷펀드거래서비스신청1600
 * 작 성 일: 2023.05.29
 * 작 성 자: 송진의
 * 수정일 : 2024-06-18 강현철  사유 : ASR240600395 고위험승인내역 화면 개선 요청 
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    
    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top"> <!-- "비밀번호" 항목 추가에 따른 DOM구조 수정 2023-04-17 jy -->
          <div class="left column w100p mr0">
            <div class="row gap40">
              <div class="wrap-input row">
                <label class="emphasis"> 고객번호 </label>
                <mo-text-field 
                  ref="ed_cust_no"
                  class="mw150"
                  :class="lv_ed_cust_no.error ? 'error' : ''"                  
                  v-model="lv_ed_cust_no.value" 
                  maxlength="7"
                  type="number"
                  @keyup="fn_SearchValid($event)"
                  :disabled='lv_btn_cust'
                />
                <mo-button class="w61" @click="fn_OpenPopup(0)" :disabled='lv_btn_cust'> 고객정보 </mo-button>
                <mo-text-field 
                  class="w130" 
                  v-model="lv_ed_cust_nm.value" 
                  disabled
                />
              </div>
              <div class="row gap6">
                <label class="emphasis"> 유선구분 </label>
                <mo-radio v-model="lv_rdo_ars_gb.value" class="flex-unset mw-auto mr14" value="N"> 일반 </mo-radio>
                <mo-radio v-model="lv_rdo_ars_gb.value" class="flex-unset mw-auto"      value="Y" disabled> 유선 </mo-radio>                
              </div>
            </div>
            <div class="row gap40 w100p">
              <div class="wrap-input row" >
                <template v-if="!lv_ed_secure_password.disabled">
                  <label class="emphasis"> 비밀번호 </label>
                  <m-trans-key-input
                      v-if="isMobile"
                      ref="ed_secure_password"
                      class="w150 flex-unset"
                      :class="lv_ed_secure_password.error ? 'error' : ''"
                      v-model="lv_ed_secure_password.value"
                      type="numberMax4"
                      dialog="Y"
                      start="0" 
                      end="-1"
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt"         
                      />
                  <mo-text-field
                    v-else-if="!isMobile"
                    ref="ed_secure_password"
                    class="w150 flex-unset"
                    :class="lv_ed_secure_password.error ? 'error' : ''"
                    v-model="lv_ed_secure_password.value"                  
                    type="password"                  
                    maxlength="4"
                  />
                </template>
              </div>
              <div class="wrap-button row">
                <!-- <mo-text-field                   
                  v-model="lv_cvr_no"                   
                  type="number"                  
                />
                <mo-button class="clear" @click="fn_ReportPrintTest(3)"> 고객/회사(T) </mo-button>
                <mo-button class="clear" @click="fn_ReportPrintTest(1)"> 고객(T) </mo-button>
                <mo-button class="clear" @click="fn_ReportPrintTest(2)"> 회사(T) </mo-button> -->
                <mo-button class="clear" @click="fn_Init()"> 초기화 </mo-button>
                <mo-button primary class="inquiry" @click="fn_DlSrvcSearch('S')"> 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>      

       <!-- fs-cust-info start -->
        <fs-cust-info
          ref="fsCustInfo"
          :propObj="pCustInfoObj"
          @fs-cust-name="fn_SetEdCustName"
        ></fs-cust-info>
        <!-- fs-cust-info end -->      

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row flex-sb">
          <h2 class="table-title"> 인터넷뱅킹 신청정보 </h2>
          <span class="fs12 colorGray" v-if="!lv_btn_delete">※ 서비스 해지 시 인터넷 펀드거래 서비스만 해지됩니다.</span>
        </div>
        <div class="wrap-table">
          <table class="table w50p th-wsN">
            <tbody>
              <tr>
                <th> 1회 이체한도 </th>
                <td>
                  <div class="wrap-input row">
                    <!-- 디자인반영 수정 (class="aR" 추가) 2023-04-05 jy -->
                    <mo-decimal-field  
	                    numeric
                      ref="ed_otm_trsf_lmt_amt"
                      class="input-long aR"
                      :class="lv_ed_otm_trsf_lmt_amt.error ? 'error' : ''"
                      v-model="lv_ed_otm_trsf_lmt_amt.value"
                      :disabled="lv_ed_otm_trsf_lmt_amt.disabled"
                      maxlength="13"                      
                    />
                    <span class="unit"> 원 </span>
                  </div>
                </td>
                <th> 1일 이체한도 </th>
                <td>
                  <div class="wrap-input row">
                    <!-- 디자인반영 수정 (class="aR" 추가) 2023-04-05 jy -->
                    <mo-decimal-field  
	                    numeric
                      ref="ed_onday_trsf_lmt_amt"
                      class="input-long aR"
                      :class="lv_ed_onday_trsf_lmt_amt.error ? 'error' : ''"
                      v-model="lv_ed_onday_trsf_lmt_amt.value"
                      :disabled="lv_ed_onday_trsf_lmt_amt.disabled"
                      maxlength="13"                      
                    />
                    <span class="unit"> 원 </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th> 최초거래 등록일 </th>
                <td>
                  <mo-date-picker
                    :class="lv_cal_int_reg_dt.error ? 'error' : ''"
                    v-model="lv_cal_int_reg_dt.value"
                    :disabled="lv_cal_int_reg_dt.disabled"
                    :bottom="false"
                  />
                </td>
                <th> </th>
                <td> </td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <div class="left">
            <mo-button v-if="isProcessCheck"
              point size="large"
              @click="fn_GoPage()"              
              :disabled="lv_btn_next"
            > 다음 </mo-button>
          </div>
          <mo-button point size="large" @click="fn_OnUpdate" :disabled="lv_btn_update"> 변경 </mo-button>
          <mo-button point size="large" @click="fn_OnDelete" :disabled="lv_btn_delete"> 해지 </mo-button>
          <mo-button primary size="large" @click="fn_OnInsert" :disabled="lv_btn_insert" > 등록 </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->
    
    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

    <!-- popup301 AML_실제소유자여부 확인-->
    <msp-fs-301p
      ref="popup301"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup301Obj"
    ></msp-fs-301p>

    <!-- popup305 고위험승인요청 -->
    <msp-fs-305p
      ref="popup305"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup305Obj"
    ></msp-fs-305p>

    <!-- popup308 -->
    <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSAmlUtil from '~/src/ui/fs/comm/FSAmlUtil'
import moment from "moment"
import FSHeader from '@/ui/fs/comm/FSHeader'
import FSAlertPopup from '@/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)  
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통)
import MSPFS301P from '~/src/ui/fs/MSPFS301P'  // AML실제고유자여부확인
import MSPFS305P from '~/src/ui/fs/MSPFS305P'  // 고위험승인요청 팝업
import MSPFS308P from '~/src/ui/fs/MSPFS308P'  // 고객정보 팝업
import FSMessageUtil from '@/ui/fs/comm/FSMessageUtil'  // message util
import FSRptUtil from '@/ui/fs/comm/FSRptUtil' 

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS109M",
  screenId: "MSPFS109M",
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'fs-cust-info': FSCustInfo,      
    'msp-fs-301p': MSPFS301P, //AML실제고유자여부확인 
    'msp-fs-305p': MSPFS305P, //고위험승인요청
    'msp-fs-308p': MSPFS308P,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()

    let t_bfcer_cust_no = '' // 프로세스 테스트용

    this.$nextTick(()=>{
      if( !FSCommUtil.gfn_isNull(this.$route.params.bfcer_cust_no) || !FSCommUtil.gfn_isNull(t_bfcer_cust_no)) {
        this.lv_ed_cust_no.value = this.$route.params.bfcer_cust_no
        if(!FSCommUtil.gfn_isNull(t_bfcer_cust_no)){
          this.lv_ed_cust_no.value = t_bfcer_cust_no
        }

        // 고객번호 매핑 후 조회
        this.fn_SearchValid({key: 'v'})
        this.fn_DlSrvcSearch('S')        
      }      
    })

    // let lv_Vm = this
    // if (process.env.NODE_ENV === 'local') {
    //   lv_Vm.$bizUtil.fsUtils.selBasInfo(lv_Vm, false).then(function (response) {
    //     lv_Vm.lv_basInfo = lv_Vm.getStore('fsStore').getters.getBasInfo.data
    //   }).catch(function (error) {
    //     window.vue.error(error)
    //   })
    // }
  },
  mounted() {
    this.fn_Init()
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isMobile: this.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
      lv_isClear: false,                                             // 보안키패드 초기화      

      // 공통 객체
      pHeaderObj: {
        title: '인터넷펀드거래서비스신청',
        step: '',
      },    
      pAlertPopupObj: {},
      lv_commCode: {},
      lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
      pCustInfoObj: {},
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,      

      // 팝업 객체
      pPopup301Obj: {},         // AML_실제소유자여부 확인
      pPopup305Obj: {},         // 고위험승인요청
      pPopup308Obj: {},

      // 검색조건
      lv_ed_cust_no: {},        // 고객번호      
      lv_ed_cust_nm: {},        // 고객명
      lv_rdo_ars_gb: {},       // 유선구분

      // 결과정보
      lv_cust_gb_nm: '',        // 고객구분 text
      lv_accd_nm: '',           // 사고압류구분 text

      lv_reObjRtn: {},              // AML실제 소유주여부 팝업 결과
      lv_reCall_yn: '',             // 자금세탁(AML) 고객확인 재이행 여부

      lv_ed_otm_trsf_lmt_amt: {},   // 1회 이체
      lv_ed_onday_trsf_lmt_amt: {}, // 1일 이체
      lv_ed_secure_password: {},    // 비밀번호
      lv_cal_int_reg_dt: {},        // 최초거래 등록일
      lv_proc_gb:{},
      lv_cust_no: {},
      lv_secu_card_no: {},
      lv_cust_nm: {},      
      lv_user_id: {},
      lv_sup_id: {},
      lv_aprv_sno: {},
      lv_ars_pswn_yn: {},

      lv_btn_delete: true,
      lv_btn_update: true,
      lv_btn_insert: true,
      lv_btn_next  : true,          // 다음
      lv_btn_status: '',            // 버튼 상태
      lv_btn_cust: false,      

      ds_search: {},                // 조회 데이터
      ds_transaction: {},           // 처리 결과 데이터 (수정, 삭제, 등록 등)
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,

      // 고객정보 펼치기/접기
      isActive: false,

      lv_isSearch: false,

      lv_isInsert: false,

      lv_masked_val: '',          // 마스크 변수 체크
      lv_cvr_no: '',


      /***********************************************************************************       
       * F10570167 : 개인정보조회
       * F10570159 : 거래서비스조회
       * F10570160 : 거래서비스등록
       * F10570161 : 거래서비스변경
       * F10570162 : 거래서비스삭제
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // ars목록
    arsType() { 
      let rtn = [];
      rtn.push({value: 'N', text: '일반'});
      rtn.push({value: 'Y', text: '유선'});
      return rtn;
    },

    isProcessCheck() {
      return this.getStore('fsStore').getters.getState.isProcess
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 프로세스 체크
    'isProcessCheck': {
      handler(after, before) {
        console.log('isProcessCheck > ', after)
        this.fn_Init()
      },
      deep: true
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
      /******************************************************************************
       * Function명 : fn_SetMaskedTxt
       * 설명       : 보안키패드 체크
       ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_val = val
      },
     /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = []
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },

      fn_SetCommCodeResult(pResult) {
        this.lv_commCode = pResult
      },

      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       * 파라미터(타입) : type (init - 초기화 시), flag (false - 활성화, true - 비활성화)
       ******************************************************************************/
      fn_Init(type = 'init', flag = true) {
        console.log('[MSPFS109M] fn_Init...')        

        // 검색정보
        // 초기화 버튼 클릭 시, 초기화 시 검색 조건 초기화
        if(type === 'init'){
          this.lv_ed_cust_no        = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_cust_no')        // 고객번호
          this.lv_ed_cust_no.error = false
          this.$refs["ed_cust_no"].focus()
          this.lv_btn_cust = false

          this.lv_reObjRtn            = {}                                                          // AML실제 소유주여부 팝업 결과
          this.lv_reCall_yn           = ''                                                          // 자금세탁(AML) 고객확인 재이행 여부
        }


        if(type === 'init' || type === 'keyup') {
          this.lv_ed_cust_nm        = FSCommUtil.gfn_dataSet(1)                                      // 고객명

          // 고객정보 초기화
          this.$refs.fsCustInfo.fn_Init()

          console.log('fn_init > ', type)

          // 비밀번호 초기화        
          this.lv_ed_secure_password       = FSCommUtil.gfn_dataSet(1, ''  , true, '', 'ed_secure_password')   // 비밀번호

          // 보안키패드 초기화
          this.lv_isClear = !this.lv_isClear ? true : false
        }


        this.lv_rdo_ars_gb              = FSCommUtil.gfn_dataSet(0, 'N', false, '')                            // 일반(N) / 유선(Y)
        
        // 인터넷뱅킹 신청정보
        this.lv_ed_otm_trsf_lmt_amt      = FSCommUtil.gfn_dataSet(0, ''  , flag, '', 'ed_otm_trsf_lmt_amt')     // 1회 이체
        this.lv_ed_onday_trsf_lmt_amt    = FSCommUtil.gfn_dataSet(0, ''  , flag, '', 'ed_onday_trsf_lmt_amt')   // 1일 이체        
        this.lv_cal_int_reg_dt           = FSCommUtil.gfn_dataSet(1, ''  , flag)   // 최초거래 등록일
        this.lv_proc_gb                  = FSCommUtil.gfn_dataSet(1, ''  , flag)
        this.lv_cust_no                  = FSCommUtil.gfn_dataSet(1, ''  , flag)        
        this.lv_secu_card_no             = FSCommUtil.gfn_dataSet(1, ''  , flag)
        this.lv_cust_nm                  = FSCommUtil.gfn_dataSet(1, ''  , flag)
        this.lv_user_id                  = FSCommUtil.gfn_dataSet(1, ''  , flag)
        this.lv_sup_id                   = FSCommUtil.gfn_dataSet(1, ''  , flag)
        this.lv_aprv_sno                 = FSCommUtil.gfn_dataSet(1, ''  , flag)
        this.lv_ars_pswn_yn              = FSCommUtil.gfn_dataSet(1, ''  , flag)
        

        this.lv_btn_update = true
        this.lv_btn_insert = true
        this.lv_btn_delete = true
        this.lv_btn_next = true

        // this.fn_SetControl(false)
      },


      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },

      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 고객정보
       ******************************************************************************/
      fn_OpenPopup(type, pData) {
        switch (type) {
          case 0:
            this.$refs.popup308.fn_Open()
            break          
          case 1:
            break
          case 'MSPFS301P':
            // AML_실제소유자여부확인
            this.pPopup301Obj = pData
            this.$refs.popup301.fn_Open()
            break
          case 'MSPFS305P':
            // 고위험승인요청
            this.pPopup305Obj = pData
            this.$refs.popup305.fn_Open()
            break
        }
      },


      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 고객정보
       ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        switch (type) {
          case 0:
            console.log('고객정보 callback...')

            // 고객번호 매핑 후 고객정보조회
            this.lv_ed_cust_no.value = pData
            this.fn_SearchValid({key: 'v'})
            break
          case 'MSPFS301P':
            console.log('AML실제소유자여부 callback...')

            // 고객번호 매핑 후 조회
            this.lv_reObjRtn = pData
            console.log(this.lv_reObjRtn)

            this.lv_ed_cust_no.value = pData.cust_no
            this.fn_OnInsert()
            break
          case 'MSPFS305P':
            console.log('고위험승인요청 callback...')

            // 고객번호 매핑 후 조회
            // ASR240600395 고위험승인내역 화면 개선 요청
            this.lv_ed_cust_no.value = pData.acct_no
            this.fn_OnInsert()
            break     
        }
      },

      /******************************************************************************
       * Function명 : fn_IsActive
       * 설명       : 고객정보 펼치기/접기
       ******************************************************************************/
      fn_IsActive() {
        this.isActive = !this.isActive;
      },

      /******************************************************************************
       * Function명 : fn_CustInfo, fn_SetEdCustName
       * 설명       : 고객정보조회
       ******************************************************************************/
      fn_CustInfo() {
        console.log('fn_CustInfo.....')

        this.pCustInfoObj.custNo = this.lv_ed_cust_no.value
        this.$refs.fsCustInfo.fn_CustInfoSearch()
      },

      fn_SetEdCustName(val) {
        // 고객명
        this.lv_ed_cust_nm.value = val

        // 고객번호 설정 (*이탈 시 커버발행 설정 목적)
        // this.$bizUtil.fsUtils.setCustNoForCvrBpl(this, val, this.lv_ed_cust_no.value)
        // 고객용 정보조회
        this.$bizUtil.fsUtils.selCustInfo(this, {bfcer_cust_no: this.lv_ed_cust_no.value})
      },

      /******************************************************************************
       * Function명 : fn_DlSrvcSearch, fn_DlSrvcSearchResult, fn_DlSrvcSearchClear
       * 설명       : 거래서비스조회
       * svcId      : selectCU160000_info
       ******************************************************************************/
      fn_DlSrvcSearch(type) {
        console.log('fn_DlSrvcSearch.....')
        
        // this.fn_DlSrvcSearchClear()
        if(type === 'S'){
          this.lv_isInsert = false
        } else {
          this.lv_isInsert = true
        }

        this.lv_ed_cust_no.error = false
        if( FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) ) {
          FSCommUtil.gfn_validate(this, '고객번호를 입력해 주십시오.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return          
        } else if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
          FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return
        }

        this.ds_search = {} // 조회 결과 데이터

        this.eaiCommObj.lv_vm = this        
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570159_S'
        this.eaiCommObj.params = {
          cust_no       : this.lv_ed_cust_no.value,     // 고객번호
          proc_gb       : 'S',                          // 프로세스구분코드          
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_DlSrvcSearchResult, this.fn_DlSrvcSearchFail)
      },

      fn_DlSrvcSearchResult(pResultData) {
        console.log('fn_DlSrvcSearchResult.....')
        
        // this.fn_DlSrvcSearchClear()
        this.fn_Init('search')
        //this.lv_ed_secure_password.value = ''
        this.lv_ed_secure_password.error = false
        this.lv_ed_secure_password.disabled = false  // 미등록 조회 시 비밀번호 활성화

        let t_popupObj = {}
        let t_data = pResultData.data
        console.log(t_data)

        // 정상 조회 = 809900
        if( t_data.error_msg == '809900' ) {          
          this.ds_search = t_data               // 조회 결과 데이터

          //this.lv_ed_cust_nm.value = t_data.int_reg_dt
          this.lv_ed_onday_trsf_lmt_amt.value = this.$bizUtil.numberWithCommas(t_data.onday_trsf_lmt_amt) // 1일 이체한도
          this.lv_ed_otm_trsf_lmt_amt.value   = this.$bizUtil.numberWithCommas(t_data.otm_trsf_lmt_amt)   // 1회 이체한도
          this.lv_cal_int_reg_dt.value        = FSCommUtil.gfn_dateReplace(1, t_data.int_reg_dt)          // 최초거래 등록일
          this.lv_secu_card_no.value          = t_data.secu_card_no
          
          this.lv_ed_onday_trsf_lmt_amt.disabled = false
          this.lv_ed_otm_trsf_lmt_amt.disabled = false          

          this.lv_btn_cust = true
          if(!this.lv_isInsert) {
            this.lv_btn_insert = true
            this.lv_btn_delete = false
            this.lv_btn_update = false
            this.lv_btn_next = false
          }
          
        // 미등록 고객 = 150003
        } else if(t_data.error_msg == '150003') {
          if(this.lv_isInsert) {
            this.fn_NextPage(this.lv_btn_status)
            return
          }

          t_popupObj = {content: FSMessageUtil.commMessage([])[t_data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)

          this.lv_ed_onday_trsf_lmt_amt.disabled = false
          this.lv_ed_otm_trsf_lmt_amt.disabled = false          

          this.lv_btn_insert = false
          this.lv_btn_delete = true
          this.lv_btn_update = true
          this.lv_btn_next = true
        } else {
          t_popupObj = {content: FSMessageUtil.commMessage([])[t_data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)
        }
      },

      fn_DlSrvcSearchFail() {
        console.log('fn_DlSrvcSearchFail.....')

        let t_popupObj = {
          cancel: false,
          confirm: true,          
          content: '검색할 고객번호가 없습니다.',
        }
        this.fn_AlertPopup(0, t_popupObj)
      },

      fn_DlSrvcSearchClear() {        
        // 검색 결과 초기화
        this.lv_ed_onday_trsf_lmt_amt.value = ''
        this.lv_ed_otm_trsf_lmt_amt.value   = ''
        this.lv_cal_int_reg_dt.value        = ''
        this.lv_secu_card_no.value          = ''
      },


      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
       ******************************************************************************/
      fn_SearchValid(event) {
        console.log('fn_SearchValid.....')

        // 고객명 초기화
        this.lv_ed_cust_nm.value = ''

        // 고객정보 펼치기 정보 초기화
        this.$refs.fsCustInfo.fn_Init()

        if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) == 7 ) {
          this.lv_ed_cust_no.error = false
          
          if(!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
            FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
            this.lv_ed_cust_no.error = true
            this.$refs['ed_cust_no'].focus()
            return 

          } else {
            if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile ) {
              // 고객정보조회
              this.fn_CustInfo()
            }
          }
          if(!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
            FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
            this.lv_ed_cust_no.error = true
            this.$refs['ed_cust_no'].focus()
            return 

          } 
        } else {
          this.fn_Init('keyup')
        }

      },

      /******************************************************************************
       * Function명 : fn_after_check
       * 자금세탁방지 (AML) KYC RULE Check
       ******************************************************************************/
      async fn_after_check() {
        console.log('fn_after_check....')

        let t_popupObj = {}


        // 자금세탁방지 (AML) KYC RULE Check 
        console.log('자금세탁방지');

        // 실제소유자여부 당일진행여부 확인
        // vue 에서 팝업 호출 문제로 AML팝업 확인시 reObjRtn 값에 따라 처리되도록 추가
        if( FSCommUtil.gfn_isNull(this.lv_reObjRtn.cust_no) || this.lv_ed_cust_no.value != this.lv_reObjRtn.cust_no ) {
          let t_real_owner_check = await FSAmlUtil.gfn_aml_real_owner_check(this, this.lv_ed_cust_no.value, this.fn_AlertPopup, this.fn_OpenPopup)
          console.log('t_real_owner_check:'+ t_real_owner_check);
          if( t_real_owner_check != true ) {
            return false
          }
        }
        // 자금세탁방지 및 테러 자금방저정책 ( AML ) 체크
        let aml_check_data = await FSAmlUtil.gfn_aml_check(this, this.lv_ed_cust_no.value, 'CU1600', '', this.lv_reCall_yn, this.lv_reObjRtn, this.fn_AlertPopup, this.fn_OpenPopup)
        if( aml_check_data != true ) {
          return false
        }          


        return true
      },

      /******************************************************************************
       * Function명 : fn_NextPage
       * 설명       : 수정/삭제/등록 후 다음 페이지 팝업
       ******************************************************************************/    
      fn_NextPage(type) {      
        if (FSCommUtil.gfn_isNull(type)){        
          return
        } else {
          if(type !== 'U' && type !== 'I' && type !== 'D'){
            return
          }
        }        

        let lv_Vm = this;
        let tmpContentTitle = ''        
        let tmpContent = [] 
        switch (type) {
          case 'I':
            tmpContentTitle = '등록완료'
            tmpContent = ['인터넷펀드거래서비스등록이 완료되었습니다.']
            break;
          case 'U':
            tmpContentTitle = '변경완료'
            tmpContent = ['인터넷펀드거래서비스변경이 완료되었습니다.']            
            break;
          case 'D':
            tmpContentTitle = '해지완료'
            tmpContent = ['인터넷펀드거래서비스해지가 완료되었습니다.']            
            break;
        }

        let t_type = 0

        if(this.isProcessCheck){
          if(this.lv_basInfo.procTyp === 'grpNew') {
            tmpContent.push('약정이체계좌등록 화면으로 이동합니다.')
            t_type = 7 // 프로세스 진행 중 마지막 화면이 아닐 경우
          } else {
            t_type = 9
          }
        } else {
          t_type = 9 // 프로세스 진행 중이 아닐 경우
        }
        
        let t_popupObj = {
          confirm: true,
          confirmFunc: lv_Vm.fn_ReportPrint,
          confirmData: {
            bfcer_cust_no: lv_Vm.lv_ed_cust_no.value
          },
          content: tmpContent,
          contentTitle: tmpContentTitle,
        }
        
        lv_Vm.fn_AlertPopup(t_type, t_popupObj)        
      },

      /******************************************************************************
       * Function명 : fn_GoPage
       * 설명       : 수정/삭제/등록 후 다음 페이지 이동     
       ******************************************************************************/    
      fn_GoPage() {        
        // alert("대리인 등록을 위한 개인(신용)정보 수집 이용 · 동의 전자서식");        
        let paramObj = {}
        let scrnId = 'MSPFS305M'
        paramObj.rmvl_srn_id = 'CU101000'
        paramObj.bfcer_cust_no = this.lv_ed_cust_no.value
        this.$router.push({ name: scrnId , params: paramObj})
      },

      /******************************************************************************
       * Function명 : fn_ReportPrintTest
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrintTest (type) {
        console.log('fn_ReportPrint....')

        let basInfo = this.getStore('fsStore').getters.getBasInfo.data
        basInfo.rprtInfo = [] // 리포트 초기화
        basInfo.formInfo = {} // 폼 정보 초기화
        basInfo.cvrPblInfo = {} // 커버발행초기화
        basInfo.docTyp = {isCust: false, isComp: false} // 커버발행초기화
        this.getStore('fsStore').dispatch('IS_CVRPBL_STOP') // 커버발행여부 설정
        this.getStore('fsStore').dispatch('BASREG', {'data': basInfo})
        let param = {
          cover_pbl_no: this.lv_cvr_no,
          cover_pbl_yn: 'Y',
          cover_pbl_ymd: '20230828'
        }
        this.$bizUtil.fsUtils.saveCvrPblInfo(this, param)

        basInfo = this.getStore('fsStore').getters.getBasInfo.data
        let tmpCust = false
        let tmpComp = false
        if(type === 3) {
          tmpCust = true
          tmpComp = true
        } else if (type === 1){
          tmpCust = true
        } else if (type === 2){
          tmpComp = true
        }
        basInfo.docTyp = {isCust: tmpCust, isComp: tmpComp} // 커버발행초기화
        this.getStore('fsStore').dispatch('BASREG', {'data': basInfo})

        console.log('basInfo >', basInfo)
        
        let formList = []        
        let FS000003 = {formId: 'FS000003'} // as-is : RCO000004 , 전자금융거래 등 신청서
        let FS000004 = {formId: 'FS000004'} // as-is : RCO000005 , 전자금융거래약관        
        let FS000005 = {formId: 'FS000005'} // as-is : RCO000005 , 전자금융거래약관        
        let FS000035 = {formId: 'FS000035'} // 수익증권저축약관

        // this.$bizUtil.fsUtils.saveCvrPblInfo(this, this.ds_transaction)
        
        let searchData = this.ds_transaction 
        searchData.issue_tr_cd        = 'CU1600'
        searchData.title_gubun1       = 'V'            
        if(this.lv_btn_status === 'I'){
          searchData.service1_gubun1  = 'V'
        }else if(this.lv_btn_status === 'U'){
          searchData.service1_gubun2  = 'V'
        }else if(this.lv_btn_status === 'D'){
          searchData.service1_gubun3  = 'V'
        }

        // // 전자서명동의서
        // let FS000000 = {formId: 'FS000000'} 
        // formList.push(FS000000)

        if(type === 3) {
          
          FS000003.params = {
            reportParam: JSON.stringify(searchData),
            reportUrl: 'reportCO070500_Info',
          }
          FS000003.mappingData = {
            // acct_nm: this.lv_ed_cust_nm, // 확인된 예금주명
            acct_nm: '', // 확인된 예금주명
          }
          formList.push(FS000003)
        } else if(type === 1){ // 고객보관용만 있을 경우          
          formList.push(FS000035)
        } else if(type === 2){  // 회사보관용만 있을 경우
          FS000005.mappingData = {
            chkAll: 'Y'
          }
          formList.push(FS000005)
        }

        console.log('formList > ', formList)
        FSInfoUtil.commReportInfo(this, formList, {}, true)
      },
      

      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrint () {
        console.log('fn_ReportPrint....')
        
        let formList = []        
        let FS000003 = {formId: 'FS000003'} // as-is : RCO000004 , 전자금융거래 등 신청서
        let FS000004 = {formId: 'FS000004'} // as-is : RCO000005 , 전자금융거래약관        

        // this.$bizUtil.fsUtils.saveCvrPblInfo(this, this.ds_transaction)
        
        let searchData = this.ds_transaction 
        searchData.issue_tr_cd        = 'CU1600'
        searchData.title_gubun1       = 'V'            
        if(this.lv_btn_status === 'I'){
          searchData.service1_gubun1  = 'V'
        }else if(this.lv_btn_status === 'U'){
          searchData.service1_gubun2  = 'V'
        }else if(this.lv_btn_status === 'D'){
          searchData.service1_gubun3  = 'V'
        }

        // // 전자서명동의서
        // let FS000000 = {formId: 'FS000000'}
        // FS000000.mappingData = {
        //   fund_nm: '테스트펀드명'
        // }
        // formList.push(FS000000)
        
        FS000003.params = {
          reportParam: JSON.stringify(searchData),
          reportUrl: 'reportCO070500_Info',
        }
        formList.push(FS000003)
       
        FS000003.mappingData = {
          // acct_nm: this.lv_ed_cust_nm, // 확인된 예금주명
          acct_nm: '', // 확인된 예금주명
        }
          
        if(this.lv_btn_status === 'I'){
          FS000004.params = {
            reportParam: JSON.stringify(searchData),
            reportUrl: 'reportCU160000_Info2',
          }
          formList.push(FS000004)
        }

        console.log('formList > ', formList)
        FSInfoUtil.commReportInfo(this, formList, this.fn_GoPage)
      },

      /******************************************************************************
       * Function명 : fn_OnInsert, fn_Insert, fn_CallBackResult
       * 설명       : 입력 이벤트
       * svcId      : insertCU160000_info
       ******************************************************************************/
      async fn_OnInsert() {
        let lv_Vm = this
        this.lv_btn_status = 'I'
        if (!lv_Vm.fn_Validate()) return

        if(!await this.fn_after_check()) {
          return
        }
        else {
          let t_popupObj = {
            confirm: true,
            confirmFunc:lv_Vm.fn_Insert,
            content: "신청하시겠습니까?"
          }
          lv_Vm.fn_AlertPopup(0, t_popupObj)
        }
      },
      fn_Insert() {
        console.log('fn_Insert >')        
        
        let lv_Vm = this
        lv_Vm.ds_transaction = {} // 처리 결과값 초기화

        lv_Vm.eaiCommObj.lv_Vm = this      
        lv_Vm.eaiCommObj.auth = "I"
        lv_Vm.eaiCommObj.commHeaderVO.eaiId = "C391_F10570160_S"
        lv_Vm.eaiCommObj.params = this.fn_GetParam('I')
        console.log('lv_Vm.eaiCommObj.params >', lv_Vm.eaiCommObj.params)
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CallBackResult)
      },
      /******************************************************************************
       * Function명 : fn_OnUpdate, fn_Update, fn_CallBackResult
       * 설명       : 수정 이벤트
       * svcId      : updateCU160000_info
       ******************************************************************************/
      fn_OnUpdate() {      
        let lv_Vm = this

        this.lv_btn_status = 'U'
        this.lv_ed_otm_trsf_lmt_amt.error = false
        this.lv_ed_onday_trsf_lmt_amt.error = false
        this.lv_cal_int_reg_dt.error = false

        if (!lv_Vm.fn_Validate()) return
        if (!lv_Vm.fn_ValidateChange()) {
          this.fn_AlertPopup(0, {content: '변경사항이 없습니다.'})
          return // 수정된 부분이 있는지 체크
        }

        let t_popupObj = {
          confirm: true,
          confirmFunc:lv_Vm.fn_Update,
          content: "변경하시겠습니까?"
        }
        lv_Vm.fn_AlertPopup(0, t_popupObj)
      },
      fn_Update() {
        console.log('fn_Update >')
        
        let lv_Vm = this
        lv_Vm.ds_transaction = {} // 처리 결과값 초기화

        lv_Vm.eaiCommObj.lv_Vm = this      
        lv_Vm.eaiCommObj.auth = "S"
        lv_Vm.eaiCommObj.commHeaderVO.eaiId = "C391_F10570161_S"
        lv_Vm.eaiCommObj.params = this.fn_GetParam('U')        
        console.log('lv_Vm.eaiCommObj.params > ', lv_Vm.eaiCommObj.params)
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CallBackResult)
      },
      /******************************************************************************
       * Function명 : fn_OnDelete, fn_Delete, fn_CallBackResult
       * 설명       : 삭제 이벤트
       * svcId      : deleteCU160000_info
       ******************************************************************************/
      fn_OnDelete() {      
        let lv_Vm = this
        this.lv_btn_status = 'D'
        if (!lv_Vm.fn_Validate()) return        

        let t_popupObj = {
          confirm: true,
          confirmFunc:lv_Vm.fn_Delete,
          content: "해지하시겠습니까?"
        }
        lv_Vm.fn_AlertPopup(0, t_popupObj)
      },
      fn_Delete() {
        console.log('fn_Delete >')        

        let lv_Vm = this
        lv_Vm.ds_transaction = {} // 처리 결과값 초기화

        lv_Vm.eaiCommObj.lv_Vm = this      
        lv_Vm.eaiCommObj.auth = "S"
        lv_Vm.eaiCommObj.commHeaderVO.eaiId = "C391_F10570162_S"
        lv_Vm.eaiCommObj.params = this.fn_GetParam('D')
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CallBackResult)
      },  
      fn_CallBackResult(pResultData) {
        let type = this.lv_btn_status        
        let t_data = pResultData.data
        let lv_Vm = this
        console.log("fn_CallBackResult.....", type , pResultData)
        
        let maxValue = ''
        if (t_data !== null && t_data !== ''){                                
          if( t_data.error_msg == '809902' && this.lv_btn_status === 'U' || 
              t_data.error_msg == '809907' && this.lv_btn_status === 'D' || 
              t_data.error_msg == '809901' && this.lv_btn_status === 'I' ) {
            this.ds_transaction = t_data  // 처리(수정,삭제,등록) 결과 데이터 (변경 여부)
            this.lv_btn_update = true
            this.lv_btn_delete = true
            this.lv_btn_insert = true
            this.lv_btn_next = true
            
            // 보안키패드 초기화
            this.lv_isClear = !this.lv_isClear ? true : false
            this.lv_ed_secure_password.value = ''
            this.lv_ed_secure_password.error = false

            switch(type){                          
              case 'U':                
              case 'I':
                // this.fn_RegCvrPblTest(t_data)
                this.fn_NextPage(this.lv_btn_status)                
                break
              case 'D':                
                this.fn_DlSrvcSearch()
                break            
            }
          } else {
            let t_popupObj = {content: FSMessageUtil.commMessage([])[t_data.error_msg]}
            lv_Vm.fn_AlertPopup(0, t_popupObj)
          } 
        }
      },
      fn_RegCvrPblTest(pParam) {
        let fromDateSearch = moment(new Date().toISOString()).add(-30, 'days').format('YYYYMMDD')
        let today = moment(new Date().toISOString()).format('YYYYMMDD')
        let t_param = {
          bfcer_cust_no: pParam.bfcer_cust_no,
          bfcer_accn_no: '',
          issnc_hstr_sno: pParam.issnc_hstr_sno,
          bfcer_cover_stat_cd: '1',
          cover_tp: '3',
          rprt_scan_nos: '1',
          from_dt: fromDateSearch,
          to_dt: today,
          tr_code: pParam.bfcer_busn_trt_id,
          rprt_frm_no: pParam.rprt_frm_no,
          frm_issnc_ymd: pParam.frm_issnc_ymd,
          dept_cd: this.lv_basInfo.brcd,
          work_empno: this.lv_basInfo.usid
        }
        this.$bizUtil.fsUtils.regCvrPbl(this, t_param, t_param, false).then(function (response) {            
        }).catch(function (error) {
          window.vue.error(error)
        })
      },      
      /******************************************************************************
       * Function명 : fn_ValidateChange
       * 설명       : 수정시 수정사항 점검
       ******************************************************************************/
      fn_ValidateChange() {
        let data = this.fn_GetParam('U')
        let count = 0
        for (const item in this.ds_search){
          if((this.ds_search[item] !== data[item]) && !FSCommUtil.gfn_isNull(this.ds_search[item]) && !FSCommUtil.gfn_isNull(data[item])) {
            if(item === 'otm_trsf_lmt_amt' || item === 'onday_trsf_lmt_amt') {            
              console.log(item, this.ds_search[item],  data[item])
              count ++
            }
          }
        }     
        return count === 0 ? false : true
      },
      /******************************************************************************
       * Function명 : fn_Validate
       * 설명       : 등록/수정 시 입력값 검증
       ******************************************************************************/
      fn_Validate() {                   
        if( FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) ) {
          FSCommUtil.gfn_validate(this, '고객번호를 입력해 주십시오.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return false         
        } else if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
          FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
          this.lv_ed_cust_no.error = true
          this.$refs['ed_cust_no'].focus()
          return false
        }

        // 일반 고정
        if(this.isMobile && this.isMtrans) {
          if ( FSCommUtil.gfn_length(this.lv_masked_val) != 4 ) {
            FSCommUtil.gfn_validate(this, '비밀번호가 입력되지 않았거나 형식에 맞지 않습니다. 확인하여 주십시오.')
            this.lv_ed_secure_password.error = true
            return false
          }
        } else {
          if( FSCommUtil.gfn_isNull(this.lv_ed_secure_password.value) || FSCommUtil.gfn_trim(this.lv_ed_secure_password.value).length < 4 ) {
            FSCommUtil.gfn_validate(this, '비밀번호가 입력되지 않았거나 형식에 맞지 않습니다. 확인하여 주십시오.')
            this.lv_ed_secure_password.error = true
            this.$refs['ed_secure_password'].focus()
            return false    
          }
        }

        if(this.lv_btn_status === 'U' || this.lv_btn_status === 'I'){
          let tmpOtmTrsfLmtAmt = this.lv_ed_otm_trsf_lmt_amt.value.replace(/,/g, '') 
          let tmpOndayTrsfLmtAmt = this.lv_ed_onday_trsf_lmt_amt.value.replace(/,/g, '') 
          if( FSCommUtil.gfn_isNull(tmpOtmTrsfLmtAmt) || FSCommUtil.gfn_trim(tmpOtmTrsfLmtAmt).length < 2) {
            FSCommUtil.gfn_validate(this, '1회이체한도가 입력되지 않았거나 형식에 맞지 않습니다.')
            this.lv_ed_otm_trsf_lmt_amt.error = true
            this.$refs['ed_otm_trsf_lmt_amt'].focus()
            return false
          }

          if( FSCommUtil.gfn_isNull(tmpOndayTrsfLmtAmt) || FSCommUtil.gfn_trim(tmpOndayTrsfLmtAmt).length < 2) {
            FSCommUtil.gfn_validate(this, '1일이체한도가 입력되지 않았거나 형식에 맞지 않습니다.')
            this.lv_ed_onday_trsf_lmt_amt.error = true
            this.$refs['ed_onday_trsf_lmt_amt'].focus()
            return false    
          }



          if( parseFloat(tmpOtmTrsfLmtAmt) > parseFloat(tmpOndayTrsfLmtAmt)) {
            FSCommUtil.gfn_validate(this, '1회 금액이 1일 금액보다 클 수 없습니다.')
            this.lv_ed_otm_trsf_lmt_amt.error = true
            this.$refs['ed_otm_trsf_lmt_amt'].focus()
            return false    
          }
        }

        return true
      },
      /******************************************************************************
       * Function명 : fn_GetParam
       * 설명       : 데이터 설정
       * 파라미터    : type (I : 등록, U: 수정, D: 삭제, P: 출력)
       * 리턴       : PO 호출용 파라미터 객체
       ******************************************************************************/
      fn_GetParam(type){
        let rtn = {}
        switch (type){
          // 등록
          case 'I':             
            rtn.otm_trsf_lmt_amt   = this.lv_ed_otm_trsf_lmt_amt.value.replace(/,/g, '')     // 1회 이체한도
            rtn.onday_trsf_lmt_amt = this.lv_ed_onday_trsf_lmt_amt.value.replace(/,/g, '')   // 1일 이체한도
            rtn.proc_gb            = this.lv_btn_status
            rtn.cust_no            = this.lv_ed_cust_no.value                                // 고객번호
            rtn.user_id            = this.lv_ed_cust_no.value
            rtn.rprt_scan_nos      = '10'
            rtn.secure_passv       = this.lv_ed_secure_password.value                        // 비밀번호 
            rtn.int_reg_dt         = moment(new Date().toISOString()).format("YYYYMMDD")
            rtn.sup_id = '' 
            // rtn.aprv_sno = '0' 
            rtn.ars_pswn_yn        = this.lv_rdo_ars_gb.value === 'N'? '' : 'Y'             // 일반(N)/유선(Y) 구분            
            break
          // 수정
          case 'U':
            rtn.otm_trsf_lmt_amt   = this.lv_ed_otm_trsf_lmt_amt.value.replace(/,/g, '')     // 1회 이체한도
            rtn.onday_trsf_lmt_amt = this.lv_ed_onday_trsf_lmt_amt.value.replace(/,/g, '')   // 1일 이체한도
            rtn.proc_gb            = this.lv_btn_status
            rtn.cust_no            = this.lv_ed_cust_no.value                                // 고객번호
            rtn.user_id            = this.lv_ed_cust_no.value
            rtn.rprt_scan_nos      = '10'
            rtn.secure_passv       = this.lv_ed_secure_password.value                        // 비밀번호            
            rtn.sup_id = '' 
            // rtn.aprv_sno = '0' 
            rtn.ars_pswn_yn        = this.lv_rdo_ars_gb.value === 'N'? '' : 'Y'             // 일반(N)/유선(Y) 구분            
            break
          // 삭제
          case 'D':            
            rtn.proc_gb            = this.lv_btn_status
            rtn.cust_no            = this.lv_ed_cust_no.value                                // 고객번호
            rtn.user_id            = this.lv_ed_cust_no.value
            rtn.rprt_scan_nos      = '10'
            rtn.secure_passv       = this.lv_ed_secure_password.value                        // 비밀번호            
            rtn.sup_id = '' 
            // rtn.aprv_sno = '0' 
            rtn.ars_pswn_yn        = this.lv_rdo_ars_gb.value === 'N'? '' : 'Y'             // 일반(N)/유선(Y) 구분            
            break
          // 출력
          case 'P':
            rtn.issue_tr_cd        = 'CU1600'
            rtn.title_gubun1       = 'V'            
            if(this.lv_btn_status === 'I'){
              rtn.service1_gubun1  = 'V'
            }else if(this.lv_btn_status === 'U'){
              rtn.service1_gubun2  = 'V'
            }else if(this.lv_btn_status === 'D'){
              rtn.service1_gubun3  = 'V'
            }
            break        
        }
        return rtn
      }
  }
};
</script>
<style scoped>
</style>